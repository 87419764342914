
import axios from "axios";
import { Component } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap";
import {DebounceInput} from 'react-debounce-input';
import { getBuyerHeaders, getBuyerUrl, getSellerHeaders, getSellerUrl } from "shared/http";
import { ToasterMessage } from 'shared/toast/toast';
import { buyerListInvoice, createChildInvoice, createNewInvoice, invoiceDetail, invoiceList, itemlist, salelist, secret_key, updateInvoice, voidInvoice,saledetail, buyerShippingAddress, bidderIrm } from "shared/settings/apiconfig";
import './InvoiceCreate.scss';
import { FEE_TYPE_LIST,CATERGORY } from "../../../../shared/settings/config"
const decimalType = ['amount', 'taxonBPamount'];
const twodecimal = ['tax', 'buyerspremium'];
const invoiceType = ['New Invoice', 'Add On Invoice'];
const feeType = FEE_TYPE_LIST
const tableItemList = ["buyerspremiumamount", "grossamount", "buyerspremium", "taxamount", "tax", "amount", "shippingfee", "handlingfee"]
const initialState = {
    billingAddr: {},
    searchvalue: "",
    billerList: [],
    pickup: {},
    additionalNotes: "",
    salelist: [],
    itemList: [],
    itemlistValue: [],
    additemDisable: false,
    ammoitem : [],
    selectedSale: {},
    loading: true,
    selectedItemList: [],
    selectedService: [],
    totalInvoiceAmount: "",
    previewModal: false,
    totalAmount: "0.00",
    totalTax: "0.00",
    totalBP: "0.00",
    totalGross: "0.00",
    shippingfee: "0.00",
    handlingfee: "0.00",
    constantItemList: [],
    itemLoading: false,
    addNotesView: false,
    invoiceType: "New Invoice",
    saleSearch: "",
    searchListView: false,
    billerSearchValue: "",
    isOnBillerSearch: false,
    isinBillerSearch: false,
    invoiceList: [],
    error: "",
    invoiceUpdateStatus: "",
    itemError: "",
    isPaidCreate: false,
    invoiceNumber: "",
    buyerToken: "",
    onsearchSale: "",
    istaxexempt: false,
    applytaxonbp: "",
    saleLocation: "",
    locationid:  "",
    invoicemessage: "",
    shippingAddr: "",
    additemloading: false,
    nonAmmoItem: []
}
class InvoiceCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            invoiceID: this.props.match.params.invoiceId,
            invoiceDetails: {},
            invoiceSubmitButtonDisable: false,
            bidderIrm:'',
            buyerid: '',
            form: {
                invoiceStatus: { show: false, type: '', message: '' },
                voidStatus: { show: false, type: '', message: '' },
            },
            cancelInvoice: false,
            changes: false,
            showShippingAddress: false,
            invoiceview: 0
        }
    }
    componentDidMount() {
        feeType.map(item => delete item.amount)
        this.setState(initialState)
        // if (this.props.match.params["status"] ===  "Paid"){
        //     this.setState({
        //         invoiceType: invoiceType[1],
        //         pickup: this.props.match.params["billingfrom"],
        //         billingAddr: this.props.match.params["billingto"],
        //         invoiceID: this.props.match.params.invoiceId 
        //     })
        //     return
        // }
        if (this.props.location.state?.name && this.props.location.state.name == "Saledashboard") {
            if (this.props.location.state?.invoiceId) {
                this.setState({
                    invoiceType: this.props.location.state &&
                        this.props.location.state?.invoiceData["invoicestatus"] &&
                        this.props.location.state?.invoiceData["invoicestatus"].toLowerCase() === "paid" ?
                        invoiceType[1] : "EDIT",
                    isPaidCreate: this.props.location.state &&
                        this.props.location.state?.invoiceData["invoicestatus"] &&
                        this.props.location.state?.invoiceData["invoicestatus"].toLowerCase() === "paid" ?
                        invoiceType[1] : "EDIT",
                    selectedSale: {
                        id: this.props.location.state?.saletitle?.["id"],
                        title: this.props.location.state?.saletitle?.["title"],
                    },
                    invoiceID: this.props.location.state?.invoiceId
                }, async () => {
                    await this.getInvoiceDetails(this.props.location.state?.invoiceId)
                    // if (this.props.location.state?.invoiceData["invoicestatus"].toLowerCase() === "paid") {
                    //     await this.getInvoiceTypeData(invoiceType[1])
                    // }
                })
            } else {
                this.setState({
                    selectedSale: {
                        id: this.props.location.state?.saletitle?.["id"],
                        title: this.props.location.state?.saletitle?.["title"],
                    },
                    pickup: this.props.location.state?.saletitle,
                    loading: false,
                    itemLoading: true
                }, () => {
                    this.getMessage(this.props.location.state?.saletitle?.["id"])
                    this.getItemList(this.props.location.state?.saletitle?.["id"])
                })
            }

        } else {
            if (this.props.match.params.invoiceId || this.state.invoiceID) {
                let id = this.state.invoiceID !== "" ? this.state.invoiceID : this.props.match.params.invoiceId
                this.setState({
                    invoiceType: this.props.location.state &&
                        this.props.location.state["status"] &&
                        this.props.location.state["status"].toLowerCase() === "paid" ?
                        invoiceType[1] : "EDIT",
                    isPaidCreate: this.props.location.state &&
                        this.props.location.state["status"] &&
                        this.props.location.state["status"].toLowerCase() === "paid"

                }, () => {
                    this.getInvoiceDetails(id)
                })
            }
            else {
                this.getBuyerToken()
            }
        }
    }
    updateInvoice = async () => {
        this.setState({ updateLoading: true });
        const data = {
            parentinvoiceid: this.state.form.id,
            invoiceamount: this.invoiceData.invoiceamount,
            details: JSON.stringify(this.invoiceData),
            buyerid: this.state.form.buyerid,
            type: this.state.form.type,
            saleid: this.state.form.saleid,
            location: this.state.saleLocation
        };

        await axios.post(getSellerUrl(updateInvoice), { data: data }, getSellerHeaders())
            .then(response => {
                // toast.success('Updated successfully');
                this.setState({
                    updateLoading: false,
                    invoiceStatus: { show: true, type: 'success', message: 'Saved' },
                    noUpdate: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            invoiceStatus: { show: false, type: 'success', message: '' }
                        },
                        );
                    }, 3000);
                });
            })
            .catch(error => {
                // toast.error(error.response.data.message);
                this.setState({
                    updateLoading: false,
                    voidStatus: { show: true, type: 'danger', message: error.response.data['message'] }
                });
            });
    };
    setUpdate = () => {
        this.setState({ updateLoading: true })
    }
    async getBuyerToken() {
        await axios.get(getSellerUrl(`${secret_key}`), getSellerHeaders()).then(
            response => {
                if (response.data.status.toLowerCase() === 'success') {

                    this.setState({
                        buyerToken: response.data.accessToken,
                        loading: false
                    })
                }
            }).catch(
                error => {
                    this.setState({
                        buyerToken: "",
                        loading: false
                    })
                }
            );
    }
    async getMessage(id) {
        await axios.get(getSellerUrl(`${saledetail}/${id}`), getSellerHeaders())
            .then(response => {
                if (response.status === 200) {
                    let resp = response.data.data[0]
                    let details = JSON.parse(resp.invoicemessage)
                    this.setState({
                        invoicemessage: resp.invoicemessage,
                    })
                }
            })
    }
        
    async getInvoiceDetails(id) {
        this.setState({
            itemLoading: true,
        })
        await axios.get(getSellerUrl(`${invoiceDetail}/${id}`), getSellerHeaders())
            .then(response => {
                if (response.status === 200) {

                    if (response.data.data[0]['invoicestatus'].toLowerCase() !== 'void') {
                        let resp = response.data.data[0]
                        let details = JSON.parse(resp.details)
                        let items = details['items'];

                        let selectedService = [];
                        feeType.map((item) => {
                            if (details[item.key]) {
                                selectedService.push({
                                    ...item,
                                    amount: details[item.key],
                                    remark: details[`${item.key}remark`]
                                })
                            }
                            return item
                        });
                        details["selectedService"] = [...selectedService]
                        resp["details"] = details
                        if (this.state.invoiceType === invoiceType[1]) {
                            selectedService = []
                            items = []
                        }
                        this.setState({
                            invoiceview: resp.invoiceview,
                            selectedSale: {
                                id: resp["saleid"],
                                title: resp["saletitle"],
                            },
                            buyerid: resp.buyerid,
                            applytaxonbp: details["applytaxonbp"],
                            istaxexempt: Array.isArray(details["excemptedtaxlocation"]) && details["excemptedtaxlocation"].includes(resp.locationid.toString()) ? true : false,
                            saleSearch: resp["saletitle"],
                            invoiceNumber: resp["invoicenumber"],
                            invoiceID: id,
                            pickup: details["billingfrom"],
                            billingAddr: details["billingto"],
                            selectedItemList: [...items],
                            invoiceDetails: { ...resp },
                            loading: false,
                            addNotesView: resp["additionalnotes"] !== "",
                            additionalNotes: resp["additionalnotes"] === null ? "" : resp["additionalnotes"],
                            selectedService: selectedService,
                            itemLoading: false,
                            saleLocation: resp.locationid,
                            locationid: resp.locationid,
                            shippingAddr: details["shippingto"],
                            // showShippingAddress: hasDeliveryAndShipping
                        }, () => {
                            if (this.state.invoiceType === "EDIT") {
                                this.getItemList(resp["saleid"])
                            }
                            this.calculateTotalInvoiceAmount()
                            this.onItemAdd() 
                            
                        })
                        if(this.state.invoiceType === "EDIT") {
                            this.getShippingBuyerAddress(resp.buyerid)
                            let validshippingaddress = false;
                            this.state.selectedItemList.forEach(item => {
                                if (item.bidderirm === "shipping" || item.bidderirm === "delivery") {
                                    validshippingaddress = true
                                }
                            });
                            this.setState({
                                showShippingAddress: validshippingaddress
                            })
                        }
                    }
                    else {
                        this.setState({
                            error: "can't continue editing void or paid invoice",
                            itemLoading: false,
                            loading: false,
                        })
                    }
                }
            })
            .catch(error => {
                if (this.state.invoiceType !== invoiceType[1])
                    this.setState({
                        error: error.message,
                        itemLoading: false,
                        loading: false,
                    });
                else
                    this.setState({
                        itemLoading: false,
                        loading: false,
                        itemError: error.message,
                    });
            });
    }
    setModal = () => {
        this.setState({ voidModal: false })
    }
    voidUpdate = () => {
        this.setState({ voidModal: true })
    }
    updateVoid = async () => {
        this.setState({ updateVoid: true });
        let data = [
            {
                "id": this.state.invoiceID
            }
        ]
        if (this.state.buyerToken === "") {
            await this.getBuyerToken()
        }
        await axios.put(getSellerUrl(voidInvoice), { data }, getSellerHeaders(this.state.buyerToken))
            .then(response => {
                this.setState({
                    updateVoid: false,
                    voidStatus: { show: true, type: 'success', message: 'Saved' },
                    voidModal: false
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            voidStatus: { show: false, type: 'success', message: '' }
                        },
                        );
                        this.onnavigatetoBack()
                    }, 1000);
                });
            })
            .catch(error => {
                this.setState({
                    updateVoid: false,
                    voidStatus: { show: true, type: 'danger', message: error?.response?.data['message'] }
                });
            });
    };
    getInvoiceList(saleId) {
        const data = {
            field: ["invoicenumber", "id","masterinvoiceid","type"],
            filter: [{
                "field": "saleid",
                "value": saleId,
                "op": "=",
                "join": "AND"
            }, {
                "field": "invoicestatus",
                "value": "paid",
                "op": "=",
                "join": "AND"
            }, {
                "field": "masterinvoiceid",
                "value": null,
                "op": "IS",
                "join": "AND"
            },
            
            ]
        }
        axios.post(getSellerUrl(invoiceList), JSON.stringify(data), getSellerHeaders())
            .then(response => {
                if (response.status === 200) {
                    let res = response.data.data
                    // res = res.filter(item => item.id === "409")
                    this.setState({
                        invoiceList: res,
                        itemLoading: false,
                        selectedItemList: []
                    })
                }
            })
            .catch(error => {
                this.setState({
                    invoiceList: [],
                })
            });
    }
    getBidderList = async (saleId, value ,locationid) => {
        let data = {
            filter: [ {
                "field": "locationid",
                "value": locationid,
                "op": "=",
                "join": "AND"
            },
            {
                "field": "saleid",
                "value": saleId,
                "op": "=",
                "join": "AND"
            },
            {
                "field": "email",
                "value": `*${value}*`,
                "op": "LIKE",
                "join": "AND"
            },
            {
                "field":"addresstype",
                "value":"Primary",
                "op":"=",
                "join":"AND"
            }
            ],
        }
        if (this.state.buyerToken === "") {
            await this.getBuyerToken()
        }
        axios.post(getBuyerUrl(`${buyerListInvoice}`), JSON.stringify(data), getBuyerHeaders(this.state.buyerToken))
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        billerList: response.data.data,
                        isOnBillerSearch: true,
                        isinBillerSearch: false
                    })
                }
            })
            .catch(error => {
                this.setState({
                    billerList: [],
                    isOnBillerSearch: true,
                    isinBillerSearch: false
                })
            });

    }

    getShippingBuyerAddress = (data) => {
        let shippAddrData = {
            filter: [{
                "field": "shipping",
                "value": 1,
                "op": "=",
                "join": "AND"
            },
            {
                "field": "buyerid",
                "value": data,
                "op": "=",
                "join": "AND"
            }]
        }
        axios.post(getSellerUrl(`${buyerShippingAddress}`), JSON.stringify(shippAddrData), getSellerHeaders())
        .then(shippingResponse => {
            if (shippingResponse.status === 200 && shippingResponse.data.data.length > 0) {
                this.setState({
                    shippingAddr: shippingResponse.data.data[0],
                    buyerid: data
                })
            }
        })
    }

    getSaleList = () => {
        this.setState({
            searchListView: true,
            onsearchSale: true,
            salelist: []
        })
        let filter = [
            { "field": "status", "value": "Closed", "op": "=", "join": "AND" },
            { "field": "title", "value": `*${this.state.saleSearch}*`, "op": "LIKE", "join": "AND" },
            { "field": "invoicegenerated", "value": 1, "op": "=", "join": "AND" }
        ];
        let field = ["title", "id", "address1", "address2", "city", "country", "contactemail","locationid", "contactmobile", "contactname", "zipcode"]

        var data = {
            "field": field,
            "filter": filter,
            "sort": [{ "field": "title", "direction": "asc" }],
        };
        axios.post(getSellerUrl(salelist), JSON.stringify(data), getSellerHeaders())
            .then(response => {
                if (response.status === 200) {
                    let res = response.data.data
                    this.setState({
                        salelist: res,
                        onsearchSale: false
                        // loading: false,
                    })
                }
            })
            .catch(error => {
                this.setState({
                    salelist: [],
                    onsearchSale: false
                })
            });
    }
    onSearchBillers(event) {
        this.setState({
            isinBillerSearch: true
        }, () =>
            this.getBidderList(this.state.selectedSale.id, this.state.billerSearchValue, this.state.pickup.locationid)
        )
    }
    onChangeInput(event) {
        this.setState({
            changes: true
        })
        if (event.target.name === "sale") {
            this.setState({
                selectedSale: event.target.value,
                saleSearch: event.target.value["title"],
                searchListView: false,
                itemLoading: true,
                showShippingAddress: false,
                buyerid: '',
                pickup: {
                    city: event.target.value["city"],
                    state: event.target.value["state"],
                    country: event.target.value["country"],
                    zipcode: event.target.value["zipcode"],
                    address1: event.target.value["address1"],
                    address2: event.target.value["address2"],
                    contactname: event.target.value["contactname"],
                    contactemail: event.target.value["contactemail"],
                    contactmobile: event.target.value["contactmobile"],
                    locationid: event.target.value["locationid"]
                },
            }, () => {
                this.getMessage(event.target.value["id"],)
                this.clearAllCache(1)
                this.getInvoiceTypeData(this.state.invoiceType)
            })
        }
        else if (event.target.name === "invoiceID") {
            let data = event.target.value.split("-")
            this.setState({
                invoiceNumber: data[1],
                invoiceID: data[0],
                itemError: ""
            })
            this.getInvoiceDetails(data[0])
        }
        else if (event.target.name === "saleSearch") {
            this.setState({
                selectedSale: {},
                [event.target.name]: event.target.value
            })
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }
    clearAllCache(stage) {
        if (stage === 3) {
            if (this.state.invoiceUpdateStatus === "Succesfully Saved") {
                setTimeout(() => {
                    this.setState({
                        ...initialState,
                        invoiceID: "",
                        invoiceDetails: {},
                        invoiceNumber: ""
                    }, () => {
                        this.onnavigatetoBack()
                    })
                }, 2000);
            }
            else {
                setTimeout(() => {
                    this.setState({
                        invoiceUpdateStatus: ""
                    })
                }, 2000);
                return
            }
        }
        // stage level => on select sale =1 , on type change =2
        if (stage === 1) {
            this.setState({
                itemList: [],
                constantItemList: [],
                invoiceList: [],
                billerList: []
            })
        }
        else if (stage === 2) {
            this.setState({
                invoiceNumber: "",
                invoiceID: "",
            })
        }
        feeType.map((item, index) => {
            feeType[index]["amount"] = ""
            feeType[index]["remark"] = ""
            return ({
                ...item,
                amount: "",
                remark: ""
            })
        })
        this.setState({
            selectedItemList: [],
            selectedService: [],
            addNotesView: false,
            additionalNotes: "",
            billerSearchValue: "",
            isOnBillerSearch: false
        }, () => {
            this.calculateTotalInvoiceAmount()
        })
    }
    getItemList = (saleID) => {
        let filter = []
        filter.push({ field: "saleid", "value": saleID, op: "=", join: "AND" })
        filter.push({ "field": "itemstatus", "value": 'Not Sold', "op": "=", "join": "AND" })
        filter.push({ field: 'isDeleted', value: 1, op: '!=', join: 'AND' })
        if (this.state.invoiceType === invoiceType[0]) {
            filter.push({ "field": "invoicenumber", "value": null, "op": "IS", "join": "AND" })
        }
        var data = {
            "sort": [{ "field": "id", "direction": "asc" }],
            "filter": filter,
            "field": ["SKU", "applytaxonbp", "buyerspremium", "buynowprice", "tax", "title", "id", "itemsoldprice", "shippingfee", "updatedbuyerpremium", "updatedtax", "invoicenumber", "handlingfee", "istaxable", "isshippable","category","itemreceiptmethod"]
        }
        axios.post(getSellerUrl(`${itemlist}`, {}), JSON.stringify(data), getSellerHeaders())
            .then((response) => {
                if (response.status === 200) {
                    response = response.data.data
                    this.setState({
                        itemlistValue: response,
                        itemList: response,
                        constantItemList: JSON.parse(JSON.stringify(response)),
                        itemLoading: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    itemList: [],
                    constantItemList: [],
                    itemLoading: false
                })
            });

    };
    captializeLetter = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }
    checkBidderIrm = async(itemId, additemloading) => {
        this.setState({
            additemloading: additemloading ? true : false
        })
        const data = {
            "buyerid": this.state.buyerid,
            "saleid": this.state.selectedSale.id,
            "itemid": itemId,
        }
         await axios.post(getSellerUrl(bidderIrm), { data: data }, getSellerHeaders())
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        bidderIrm: response.data.data.bidderirm,
                        additemloading: false
                    })
                    
                }
            })
            .catch((error) => {
                this.setState({
                    additemloading: false
                })
            });

    }
    bidderirmcheck = (bidderirmvalue) => {
        if(bidderirmvalue == 'pickup'){
            return 'P'
        }
        else if(bidderirmvalue == 'shipping'){
            return 'S'
        }
        else if(bidderirmvalue == 'delivery'){
            return 'D'
        }
        else{
            return `- - -`
        }

    }

    onItemAdd = async(additem) => {
       
        let selecteditem = this.state.itemList.filter(data => this.state.selectedItemList.findIndex(item => data.id === item.id) < 0)
        let itemvalue = selecteditem[0];
        if(additem) {
            this.setState({
                changes: true,
                invoiceSubmitButtonDisable: false,
            })
             await this.checkBidderIrm(itemvalue.id, true)
        }

        let ammoItem = this.state.itemList.filter(obj => CATERGORY.includes(obj.category))
        let nonAmmoItem = this.state.itemList.filter(obj => !CATERGORY.includes(obj.category))
        if(this.state.selectedItemList.length !== 0){

            let item = this.state.selectedItemList[0]

            if(CATERGORY.includes(item.category)){
                    this.setState({
                        itemList: ammoItem,
                        additemDisable: false
                      })
                
            }
            else{
                  this.setState({
                    itemList: nonAmmoItem,
                    additemDisable: false
                  })

            }

        }
        else if(ammoItem.length === 1){
            this.setState({
                nonAmmoItem: nonAmmoItem,
                ammoitem: ammoItem,
                additemDisable: ( this.state.itemList.length === 1  ||  (ammoItem.length === 1 && nonAmmoItem.length === 1)) ?  true : false
              })

        }
        setTimeout(() => {
            let itemList = [];
            if (this.state.invoiceType != invoiceType[1]) {
                itemList = this.state.itemList.filter(data => this.state.selectedItemList.findIndex(item => data.id === item.id) < 0)
            }            
            let hasDeliveryAndShipping =   this.state.selectedItemList.some(item => {
                const parsedItemReceiptMethod = item.itemreceiptmethod ? JSON.parse(item.itemreceiptmethod) : '';
                return parsedItemReceiptMethod ? parsedItemReceiptMethod.delivery || parsedItemReceiptMethod.shipping : false;
            });
            if (itemList.length > 0) {
                let item = itemList[0];
                let irmvalue = item.itemreceiptmethod ? JSON.parse(item.itemreceiptmethod) : '';

                let itemreceiptmethod = Object.entries(irmvalue).filter(([key, value]) => value === true)
                .map(([key, value]) => key);
                if (itemreceiptmethod) {
                    const irm = ['pickup', 'shipping', 'delivery'];
                    itemreceiptmethod = itemreceiptmethod.sort((a, b) => {
                        return irm.indexOf(a) - irm.indexOf(b);
                    });
                }
                item['bidderirm'] =  this.state.bidderIrm ? this.state.bidderIrm : itemreceiptmethod[0] ? itemreceiptmethod[0] : ""
                
                this.setState({
                    selectedItemList: [
                        ...this.state.selectedItemList,
                        { ...item }
                    ]
                }, () => {
                    let validshipping = false;
                    this.state.selectedItemList.forEach(item => {
                        if (item.bidderirm === "shipping" || item.bidderirm === "delivery") {
                            validshipping = true
                        }
                    });

                    this.setState({
                        showShippingAddress: validshipping
                    })
                })
            }
            
        }, 10);  
    }
    async onchangeItem(event, index) {
        this.setState({
            changes: true
        })
        let selectedItemList = [...this.state.selectedItemList]
        let selectedItem = selectedItemList[index]
        this.state.applytaxonbp = (isNaN(selectedItem['applytaxonbp']) || selectedItem['applytaxonbp'] === "") ? this.state.applytaxonbp : selectedItem['applytaxonbp'];
        if (event.target.name === "title") {
            selectedItem = this.state.itemList[event.target.value]
            await this.checkBidderIrm(selectedItem.id)
            let itemreceiptmethod = selectedItem.itemreceiptmethod && typeof selectedItem.itemreceiptmethod === 'string' ? Object.entries(JSON.parse(selectedItem.itemreceiptmethod)).filter(([key, value]) => value === true)
            .map(([key, value]) => key) : Object.entries(selectedItem.itemreceiptmethod).filter(([key, value]) => value === true)
            .map(([key, value]) => key) 

            if (itemreceiptmethod) {
                const irm = ['pickup', 'shipping', 'delivery'];
                itemreceiptmethod = itemreceiptmethod.sort((a, b) => {
                    return irm.indexOf(a) - irm.indexOf(b);
                });
            }

            selectedItem['bidderirm'] = this.state.bidderIrm ? this.state.bidderIrm : itemreceiptmethod[0] ? itemreceiptmethod[0] : ""
            tableItemList.map((item) => {
                let val = this.state.itemList[event.target.value][item]
                selectedItem[item] = typeof val !== "undefined" ? val : "";
                return item
            });
            selectedItemList[index] = selectedItem
            this.setState({
                selectedItemList: selectedItemList,
                additemDisable: this.state.ammoitem.length === 1  && CATERGORY.includes(selectedItem.category)  || ( this.state.ammoitem.length === 1 && this.state.nonAmmoItem.length === 1) ? true : false
            }, () => { this.calculateTotalInvoiceAmount() })
        }
        else if(event.target.name === "bidderirm"){
            selectedItem['bidderirm'] = event.target.value
            this.setState({
                selectedItemList: selectedItemList,
            },()=>{
                let validshippingaddress = false;
                this.state.selectedItemList.forEach(item => {
                    if (item.bidderirm === "shipping" || item.bidderirm === "delivery") {
                        validshippingaddress = true
                    }
                });

                this.setState({
                    showShippingAddress: validshippingaddress
                })

            })
        }
        else {
            if (decimalType.includes(event.target.name)) {
                let threeDecimal = ['amount', 'taxonBPamount'];
                if (threeDecimal.includes(event.target.name)) {
                    if (event.target.value) {
                        if (event.target.value) {
                            event.target.value = event.target.value
                                .replace(/[^\d.]/g, '')
                                .replace(new RegExp("(^[\\d]{" + 10 + "})[\\d]", "g"), '$1')
                                .replace(/(\..*)\./g, '$1')
                                .replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1');
                        }
                        if (!event.target.value.match(/^\d{0,5}(\.$|\.\d{0,2}$|$)/)) {
                            if (isNaN(event.target.value))
                                event.target.value = '';
                        }
                    }
                }
            }
            if (twodecimal.includes(event.target.name)) {
                let threeDecimal = ['buyerspremium', 'tax'];
                if (threeDecimal.includes(event.target.name)) {
                    if (event.target.value) {
                        if (event.target.value) {
                            event.target.value = event.target.value
                                .replace(/[^\d.]/g, '')
                                .replace(new RegExp("(^[\\d]{" + 3 + "})[\\d]", "g"), '$1')
                                .replace(/(\..*)\./g, '$1')
                                .replace(new RegExp("(\\.[\\d]{" + 3 + "}).", "g"), '$1');
                        }
                        if (!event.target.value.match(/^\d{0,3}(\.$|\.\d{0,3}$|$)/)) {
                            if (isNaN(event.target.value))
                                event.target.value = '';
                        }
                    }
                }
            }
            selectedItem[event.target.name] = event.target.value
            let amount = (isNaN(selectedItem['amount']) || selectedItem['amount'] === "") ? 0 : parseFloat(parseFloat(selectedItem['amount']).toFixed(2));
            if (['amount', 'buyerspremium', 'tax'].includes(event.target.name)) {
                let taxamount = 0;
                let buyerspremiumamount = 0 ;
                let taxonBPamount = 0 ;
                if (!this.state.istaxexempt && selectedItem['istaxable']) {
                     taxamount = parseFloat(parseFloat((amount * selectedItem['tax']) / 100).toFixed(2));
                    selectedItem['taxamount'] = taxamount;
                } else {
                    selectedItem['taxamount'] = "0";
                }

                buyerspremiumamount = parseFloat(parseFloat((amount * selectedItem['buyerspremium']) / 100).toFixed(2));
                selectedItem['buyerspremiumamount'] = buyerspremiumamount;

                if (!this.state.istaxexempt && selectedItem['istaxable'] && this.state.applytaxonbp) {
                    taxonBPamount = parseFloat(parseFloat((selectedItem['buyerspremiumamount'] * selectedItem['tax']) / 100).toFixed(2));
                    selectedItem['taxonBPamount'] = taxonBPamount;
                } else {
                    selectedItem['taxonBPamount'] = "0";
                }
                const grossamount = parseFloat(amount + buyerspremiumamount + (this.state.istaxexempt ? 0 : (taxamount + (this.state.applytaxonbp ? taxonBPamount : 0)))).toFixed(2);

                selectedItem['grossamount'] = (isNaN(grossamount)) ? 0.0 : parseFloat(grossamount);

            }
            selectedItemList[index] = selectedItem
            this.setState({
                selectedItemList: selectedItemList
            }, () =>
                this.calculateTotalInvoiceAmount())
        }
    }
    async oncalculateTaxException() {
        let selectedItems = this.state.selectedItemList;
        console.log(selectedItems,"selectedItems")
        selectedItems.map((item, index) => {
            let selectedItem = {
                ...selectedItems[index],
                tax: this.state.itemList[this.state.itemList.findIndex(t => t.id === item.id)]["tax"]
            }
            let amount = (isNaN(selectedItem['amount']) || selectedItem['amount'] === "") ? 0 : parseFloat(selectedItem['amount'])
            if (!this.state.istaxexempt && selectedItem['istaxable']) {
                const taxamount = parseFloat(amount * selectedItem['tax']) / 100;
                selectedItem['taxamount'] = parseFloat(taxamount).toFixed(2);
            } else {
                selectedItem['taxamount'] = "0"
            }
            const buyerspremiumamount = parseFloat(amount * selectedItem['buyerspremium']) / 100;
            selectedItem['buyerspremiumamount'] = parseFloat(buyerspremiumamount).toFixed(2);

            if (!this.state.istaxexempt && selectedItem['istaxable'] && this.state.applytaxonbp) {
                const taxonBPamount = parseFloat(selectedItem['buyerspremiumamount'] * selectedItem['tax']) / 100;
                selectedItem['taxonBPamount'] = parseFloat(taxonBPamount).toFixed(2);
            } else {
                selectedItem['taxonBPamount'] = "0"
            }
            const grossamount = parseFloat(amount) +
                parseFloat(selectedItem['buyerspremiumamount'] ? selectedItem['buyerspremiumamount'] : 0) +
                (this.state.istaxexempt ? 0 : (parseFloat(selectedItem['taxamount'] ? selectedItem['taxamount'] : 0) +
                    (this.state.applytaxonbp ? parseFloat(selectedItem['taxonBPamount'] ? selectedItem['taxonBPamount'] : 0)
                        : 0)));

            selectedItem['grossamount'] = (isNaN(grossamount)) ? 0.0 : parseFloat(grossamount).toFixed(2);
            selectedItems[index] = selectedItem
            return item
        })
        this.setState({
            selectedItemList: selectedItems
        }, () =>
            this.calculateTotalInvoiceAmount())

    }
    calculateTotalInvoiceAmount(removedItem) {
        var totalInvoiceAmount = 0.0
        var totalAmount = 0.0
        var totalTax = 0.0
        var totalBP = 0.0
        var totalGross = 0.0
        this.state.selectedItemList.forEach(item => {
            if (item["amount"] && item["amount"] !== "") {
                totalAmount = parseFloat(item["amount"]) + totalAmount
                totalTax = !this.state.istaxexempt ? parseFloat(item["taxamount"]) + totalTax : '0.00'
                totalBP = parseFloat(item["buyerspremiumamount"]) + totalBP
                totalGross = parseFloat(item["grossamount"]) + totalGross
            }
        })
        let hasDeliveryAndShipping =   this.state.selectedItemList.some(item => {
            const parsedItemReceiptMethod = item.itemreceiptmethod ? JSON.parse(item.itemreceiptmethod) : '';
            return parsedItemReceiptMethod ? parsedItemReceiptMethod.delivery || parsedItemReceiptMethod.shipping : false;
        });
        
        totalInvoiceAmount = !isNaN(totalGross) ? totalGross : totalInvoiceAmount
        this.state.selectedService.forEach(item => {
            // if (removedItem) {
            //     let shipping = parseFloat(removedItem.shippingfee);
            //     let handling = parseFloat(removedItem.handlingfee);
            //     if (item["name"] === "Shipping" && shipping) {
            //         item["amount"] = parseFloat(item["amount"]) - shipping;
            //     }
            //     if (item["name"] === "Handling" && handling) {
            //         item["amount"] = parseFloat(item["amount"]) - handling;
            //     }
            // }
            if (item["amount"] && item["amount"] !== "") {
                totalInvoiceAmount = parseFloat(item["amount"]) + parseFloat(totalInvoiceAmount)
            }

        })
        totalInvoiceAmount = !isNaN(totalInvoiceAmount) ? totalInvoiceAmount : 0.0
        this.setState({
            totalInvoiceAmount: parseFloat(totalInvoiceAmount).toFixed(2),
            totalAmount: parseFloat(totalAmount).toFixed(2),
            totalTax: parseFloat(totalTax).toFixed(2),
            totalBP: parseFloat(totalBP).toFixed(2),
            totalGross: parseFloat(totalGross).toFixed(2)
        })
    }
    onServiceAdd() {
        let itemList = [...feeType].filter(type => this.state.selectedService.findIndex(service => type.name === service.name) < 0)
        if (itemList.length > 0) {
            this.setState({
                invoiceSubmitButtonDisable: false,
                selectedService: [
                    ...this.state.selectedService,
                    itemList[0],
                ]
            })
        }
    }
    onNotesAdd() {
        this.setState({
            changes: true,
            addNotesView: !this.state.addNotesView, additionalNotes: ""
        })
    }
    onPreview() {
        this.setState({
            previewModal: true
        })
    }
    async onSubmit() {
        this.setState({
            invoiceSubmitButtonDisable: true
        })
        if (this.state.invoiceType === "EDIT") {
            this.editInvoice()
        }
        else {
            let servicelist = {}
            this.state.selectedService.map(item => {
                servicelist[item.key] = item.amount
                servicelist[`${item.key}remark`] = item["remark"]
                return item
            })
            let details = {
                saleid: this.state.selectedSale["id"],
                items: [],
                location: this.state.saleLocation,
                billingto: this.state.billingAddr,
                billingfrom: this.state.pickup,
                totalamount: this.state.totalInvoiceAmount,
                invoiceamount: this.state.totalInvoiceAmount,
                invoicemessage: this.state.invoicemessage,
                ...servicelist,
                istaxexempt: this.state.istaxexempt ? 1 : 0,
            }
            if(this.state.shippingAddr && this.state.showShippingAddress){
                details['shippingto']  = this.state.shippingAddr
            }
            if (this.state.invoiceType === invoiceType[0]) {

                // this.state.selectedItemList.forEach(elem => {
                //     if (elem.itemdrowpdownkey) {
                //         elem.bidderirm = elem.itemdrowpdownkey;
                //         delete elem.itemdrowpdownkey;
                //     }
                // });
                details["items"] = this.state.selectedItemList
                const data = {
                    invoiceamount: this.state.totalInvoiceAmount,
                    details: JSON.stringify(details),
                    buyerid: this.state.billingAddr["id"],
                    type: "Purchase",
                    saleid: this.state.selectedSale["id"],
                    additionalnotes: this.state.additionalNotes,
                    location: this.state.saleLocation
                };
                await axios.post(getSellerUrl(createNewInvoice), { data: data }, getSellerHeaders())
                    .then(response => {
                        if (response.status === 200 && response.data.status === "success") {
                            this.setState({
                                invoiceUpdateStatus: "Succesfully Saved"
                            })
                        }
                        else {
                            this.setState({
                                invoiceUpdateStatus: "Failed to Save"
                            })
                        }
                        this.clearAllCache(3)
                    })
                    .catch(error => {
                        this.setState({
                            invoiceUpdateStatus: error.message,
                            invoiceSubmitButtonDisable: false
                        })
                        this.clearAllCache(3)
                    });
            }
            else {
                const data = {
                    masterinvoiceid: this.state.invoiceID,
                    masterinvoicenumber: this.state.invoiceNumber,
                    invoiceamount: this.state.totalInvoiceAmount,
                    details: JSON.stringify(details),
                    buyerid: this.state.billingAddr["id"],
                    type: "Purchase",
                    saleid: this.state.selectedSale["id"],
                    additionalnotes: this.state.additionalNotes,
                    location: this.state.saleLocation
                };
                await axios.post(getSellerUrl(createChildInvoice), { data: data }, getSellerHeaders())
                    .then(response => {
                        if (response.status === 200 && response.data.status === "success") {
                            this.setState({
                                invoiceUpdateStatus: "Succesfully Saved"
                            })
                        }
                        else {
                            this.setState({
                                invoiceUpdateStatus: "Failed to Save"
                            })
                        }
                        this.clearAllCache(3)
                    })
                    .catch(error => {
                        this.setState({
                            invoiceUpdateStatus: error.message,
                            invoiceSubmitButtonDisable: false
                        })
                        this.clearAllCache(3)
                    });
            }
        }
        // var service = {}
        // this.state.selectedService.map(item => {
        //     service[item.key] = item.amount
        //     return item
        // })
        // const details = {
        //     ...service,
        //     "items": this.state.selectedItemList,
        //     "saleid": this.state.selectedSale.id,
        //     "location": 1,
        //     "billingto": this.state.billingAddr,
        //     "billingfrom": this.state.pickup,
        //     "totalamount": parseFloat(this.state.totalAmount).toFixed(2),
        //     "applytaxonbp": 1,
        //     "invoiceamount": parseFloat(this.state.totalInvoiceAmount).toFixed(2),
        //     "invoicenumber": "",
        //     "invoicemessage": "{\"content\": {\"body\": \"<p><span style=\\\"color: black;\\\">1. Invoices will be sent out once the sale has completely closed. Payment is due immediately upon receipt of invoice.</span></p><p><span style=\\\"color: black;\\\">2. Login to your CTBids Account: Invoices&gt;Purchases&gt;Check out button&gt; Choose Paypal or select the option below the PayPal login to use a debit or credit card.</span></p><p><span style=\\\"color: black;\\\">3. Payments must be made no later than&nbsp;noon&nbsp;</span><span style=\\\"color: black; background-color: yellow;\\\">Month/Day</span><span style=\\\"color: black;\\\">. You can pay with a major credit card or PayPal. Caring Transitions reserves the right to re-sell items that are not paid for by noon the day before pick-up.</span></p><p><span style=\\\"color: black;\\\">4. Should you have any issues regarding payment of an invoice, please e-mail&nbsp;</span><span style=\\\"color: black; background-color: yellow;\\\">Seller First Name at&nbsp;</span><a href=\\\"mailto:sellername@caringtransitions.com\\\" rel=\\\"noopener noreferrer\\\" target=\\\"_blank\\\" style=\\\"color: rgb(17, 85, 204); background-color: yellow;\\\">sellername@caringtransitions.com</a><span style=\\\"color: black; background-color: yellow;\\\">&nbsp;(be sure to hyperlink email)&nbsp;or call/text :seller phone number.</span></p>\"}}",
        //     "invoiceversion": 0,
        //     "excemptedtaxlocation": [

        //     ]
        // }
        // const data = {
        //     parentinvoiceid: "",
        //     invoiceamount: parseFloat(this.state.totalInvoiceAmount).toFixed(2),
        //     details: JSON.stringify(details),
        //     buyerid: 75,
        //     type: "Purchase",
        //     saleid: this.state.selectedSale.id,
        //     additionalnotes: this.state.additionalNotes
        // }
    }
    onnavigatetoBack() {
        const state = this.props.location.state;
        if (this.props.location.state !== undefined && this.props.location.state.name === "Saledashboard") {
            this.props.history.push({ pathname: this.props.location.state.prevPath, state: state })
            return true
        }
        else if (state !== undefined && state["status"] && state["status"].toLowerCase() === "paid" &&
            this.props.match.params.invoiceId) {
            this.props.history.goBack()
            return true
        }
        else {
            this.props.history.push({ pathname: '/invoice', state: this.props.location.state });
            return true
        }
    }
    onchangeService(event, index) {
        this.setState({
            changes: true
        })
        let selectedService = [...this.state.selectedService];
        if (event.target.name === "service") {
            selectedService[index]["amount"] = ""
            selectedService[index]["remark"] = ""
            selectedService[index] = feeType[event.target.value]
        }
        else {
            if (decimalType.includes(event.target.name)) {
                let threeDecimal = ['amount'];
                if (threeDecimal.includes(event.target.name)) {
                    if (event.target.value) {
                        if (event.target.value) {
                            event.target.value = event.target.value
                                .replace(/[^\d.]/g, '')
                                .replace(new RegExp("(^[\\d]{" + 10 + "})[\\d]", "g"), '$1')
                                .replace(/(\..*)\./g, '$1')
                                .replace(new RegExp("(\\.[\\d]{" + 2 + "}).", "g"), '$1');
                        }
                        if (!event.target.value.match(/^\d{0,5}(\.$|\.\d{0,2}$|$)/)) {
                            if (isNaN(event.target.value))
                                event.target.value = '';
                        }
                    }
                }
            }
            selectedService[index][event.target.name] = event.target.value
        }
        this.setState({
            selectedService
        }, () => this.calculateTotalInvoiceAmount())
    }
    async getInvoiceTypeData(type) {
        this.setState({
            invoiceType: type,
            itemError: ""
        }, () => {
            this.clearAllCache(2)
        })
        if (type === invoiceType[0]) {
            await this.getItemList(this.state.selectedSale.id)
        }
        else if (type === invoiceType[1]) {
            this.state.invoiceList.length >= 0 && await this.getInvoiceList(this.state.selectedSale.id)

        }
    }
    searchBidder = (value) => {
        this.setState({
            billerSearchValue: value,
            isOnBillerSearch: false
        })
        this.onSearchBillers();
    }
    
    onRemoveItem(type, item, index) {
        this.setState({
            changes: true
        })
        let list = this.state[type]
        if (type === "selectedItemList") {
            let itemIndex = this.state.itemList.indexOf(item)
            if (itemIndex >= 0) {
                tableItemList.map((item) => {
                    let val = this.state.constantItemList[itemIndex][item]
                    list[index][item] = val ? val : ""
                    return item
                })
            }
        }
        else {
            let itemIndex = feeType.indexOf(item)
            feeType[itemIndex] = {
                ...feeType[itemIndex],
                amount: "",
                remark: ""
            }
        }
        list.splice(index, 1)
        let irmvalue = item.itemreceiptmethod ? JSON.parse(item.itemreceiptmethod) : '';
        let itemreceiptmethod = Object.entries(irmvalue).filter(([key, value]) => value === true).map(([key, value]) => key);

        let validshippingaddress = false;
        this.state.selectedItemList.forEach(item => {
            if (item.bidderirm === "shipping" || item.bidderirm === "delivery") {
                validshippingaddress = true
            }
        });
        
        this.setState({
            itemList: this.state.selectedItemList.length === 1  ? this.state.itemlistValue : this.state.itemList,
            [type]: list,
            // invoiceSubmitButtonDisable: list.length > 0 ? false : true,
            showShippingAddress: validshippingaddress,
            additemDisable: false
        }, () => this.calculateTotalInvoiceAmount(item))

    }
    async editInvoice() {
        let servicelist = {}
        this.state.selectedService.map(item => {
            servicelist[item.key] = item.amount
            servicelist[`${item.key}remark`] = item["remark"]
            return item
        })

        // this.state.selectedItemList.forEach(elem => {
        //     if (elem.itemdrowpdownkey) {
        //         elem.bidderirm = elem.itemdrowpdownkey;
        //         delete elem.itemdrowpdownkey;
        //     }
        // });
        let details = {
            items: this.state.selectedItemList,
            saleid: this.state.invoiceDetails.saleid,
            location: this.state.invoiceDetails.location,
            billingto: this.state.billingAddr,
            billingfrom: this.state.pickup,
            totalamount: this.state.totalInvoiceAmount,
            applytaxonbp: this.state.invoiceDetails.details.applytaxonbp,
            invoiceamount: this.state.totalInvoiceAmount,
            invoicenumber: this.state.invoiceDetails.details.invoicenumber,
            invoicemessage: this.state.invoiceDetails.details.invoicemessage,
            ...servicelist,
            excemptedtaxlocation: this.state.invoiceDetails.details.excemptedtaxlocation,
            istaxexempt: this.state.istaxexempt ? 1 : 0,
            shippingto: this.state.shippingAddr
        }
        const data = {
            parentinvoiceid: this.state.invoiceID,
            invoiceamount: this.state.totalInvoiceAmount,
            details: JSON.stringify(details),
            buyerid: this.state.invoiceDetails.buyerid,
            type: this.state.invoiceDetails.type,
            saleid: this.state.invoiceDetails.saleid,
            additionalnotes: this.state.additionalNotes,
            location: this.state.saleLocation
        };
        await axios.post(getSellerUrl(updateInvoice), { data: data }, getSellerHeaders())
            .then(response => {
                if (response.status === 200 && response.data.status === "success") {
                    this.setState({
                        invoiceUpdateStatus: "Succesfully Saved"
                    })
                }
                else {
                    this.setState({
                        invoiceUpdateStatus: "Failed to Save"
                    })
                }
                this.clearAllCache(3)
            })
            .catch(error => {
                this.setState({
                    invoiceUpdateStatus: error.message,
                    invoiceSubmitButtonDisable: false
                })
                this.clearAllCache(3)
            });
    }
    previewSubmitDisable(type) {
        if (!((this.state.selectedItemList.length > 0 && !this.state.invoiceDetails?.masterinvoiceid) || this.state.selectedService.length > 0)) {
            return true
        }
        if (this.state.invoiceType === invoiceType[0]) {
            if (this.state.selectedItemList.filter(item => item["amount"] && item["amount"] !== "").length !== this.state.selectedItemList.length) {
                return true
            }
        }
        
        if (this.state.selectedService.filter(item => item["amount"] && item["amount"] !== "").length !== this.state.selectedService.length) {
            return true
        }
        if (this.state.invoiceType === "EDIT") {
            if (Object.keys(this.state.invoiceDetails).length > 0) {
                if (this.state.invoiceID !== "" && this.state.invoiceNumber !== "") {
                    if (this.state.invoiceDetails["details"]["selectedService"].length > this.state.selectedService.length) {

                        return false
                    }
                    if (this.state.invoiceDetails["details"]["items"].length > this.state.selectedItemList.length) {
                        return false
                    }
                    // this.state.invoiceDetails["details"]["items"].map((item)=>{
                    //     let index = this.state.selectedItemLis.findIndex(item)
                    //     if( index >= 0 && this.state.selectedItemList[index]["amount"] !== item["amount"]) {
                    //         return false
                    //     }
                    // })
                }
            }
        }
        else {

            if (!(this.state.totalInvoiceAmount > 0)) {
                return true
            }
        }
        if (!Object.keys(this.state.billingAddr).length > 0 && Object.keys(this.state.pickup).length > 0) {
            return true
        }
        if (this.state.invoiceType === "EDIT" || this.state.invoiceType === invoiceType[1]) {
            if (this.state.invoiceID === "" && this.state.invoicenumber === "") {
                return true
            }
        }
        return false
    }
    render() {
        if (this.state.loading) {
            return (
                <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                    <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                </div>)
        }
        else if (!this.state.loading && this.state.error) {
            return (
                <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                    <div className="errormessage">{this.state.error}</div>
                </div>)
        }
        return (
            <div className="invoice-create-container">
                {this.state.invoiceUpdateStatus && <div className="invoieupdate">
                    <div style={this.state.invoiceUpdateStatus !== "Succesfully Saved" ? {
                        color: "red",
                    } : {}}>
                        {this.state.invoiceUpdateStatus}
                    </div>
                </div>}
                <div className="header">
                    <div id="backbutton" style={{ padding: "1%" }}>
                        <span className="clickable" onClick={() => {
                            if (this.state.changes == true) {
                                this.setState({
                                    cancelInvoice: true
                                })
                            } else {
                                this.onnavigatetoBack()
                            }
                        }} >
                            <i className=" fa fa-chevron-circle-left fa-lg text-back rounded-circle"></i>
                            <span style={{ paddingLeft: "1em" }}>Back</span>
                        </span>
                        {/* <span style={{ marginLeft: "0.5em", paddingLeft: "1em", borderLeft: "1px solid", color: "#A12142" }}>{this.edit ? 'Create New Sale' : 'Sale Edit'}</span> */}
                    </div>
                    <div className="header-button">
                        {/* {
                            this.state.invoiceStatus.show ?
                                <ToasterMessage type={this.state.invoiceStatus.type} message={this.state.invoiceStatus.message} className="alert-handle mt-2"></ToasterMessage>
                                : null
                        } */}
                        {this.state.invoiceType === "EDIT" && <Button className="save-button m-1" onClick={() => {
                            if (this.state.changes == true) {
                                this.setState({
                                    cancelInvoice: true
                                })
                            } else {
                                this.onnavigatetoBack()
                            }
                        }}  >Cancel</Button>}
                        {this.state.invoiceType === "EDIT" && <Button className="save-button m-1" onClick={this.voidUpdate} >Void</Button>}
                        <Button className="save-button m-1"
                            disabled={this.previewSubmitDisable("preview")}
                            onClick={() => this.onPreview()}>Preview</Button>
                        <Button disabled={!this.state.changes || this.state.invoiceSubmitButtonDisable || this.previewSubmitDisable("submit")} className="save-button m-1" onClick={() => this.onSubmit()}>Submit</Button>
                    </div>
                </div>
                <Modal style={{ padding: "10px" }} centered animation={false} backdrop="static" show={this.state.cancelInvoice} id="invoiceVoidModal" className="suspendModal" >
                    <Modal.Body className="d-flex justify-content-end align-items-end">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            Are you sure you want to discard this invoice?
                            <div className="d-flex flex-row mt-4">
                                <Button onClick={() => this.onnavigatetoBack()} className="btn-white"> Yes </Button>
                                <Button onClick={() => { this.setState({ cancelInvoice: false }) }} className="btn-secondary ml-2">No</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal style={{ padding: "10px" }} centered animation={false} backdrop="static" show={this.state.updateLoading} id="invoiceVoidModal" className="suspendModal" >
                    <Modal.Body className="d-flex justify-content-end align-items-end">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            Invoice will be voided and a new one created proceed?
                            <div className="d-flex flex-row mt-4">
                                <Button onClick={this.updateInvoice} className="btn-white"> Yes </Button>
                                <Button onClick={() => this.setState({ updateLoading: false })} className="btn-secondary ml-2">No</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal style={{ padding: "10px" }} centered animation={false} backdrop="static" show={this.state.voidModal} id="invoiceVoidModal" className="suspendModal" >
                    <Modal.Body className="d-flex justify-content-end align-items-end">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            Are you sure you want to void this invoice?
                            <div className="d-flex flex-row mt-4">
                                <Button onClick={this.updateVoid} className="btn-white" disabled={this.state.updateVoid}> Yes </Button>
                                <Button onClick={this.setModal} className="btn-secondary ml-2">No</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {(this.state.invoiceType === "EDIT") &&
                    <div>
                        <div className="splitter"></div>
                        <div className="invoice-number-container">
                            <div></div>
                            <div className="invoice-number-content">
                                <p className="invoice-number-left">Invoice Number : </p>
                                <p className="invoice-number-right">{this.state.invoiceNumber}</p>
                            </div>
                        </div>
                        <div className="splitter"></div>
                    </div>
                }
                {this.state.invoiceType !== "EDIT" && <div>
                    <div className="splitter"></div>
                    <div className="sale-container">
                        <div className="sale-title">Sale Title</div>
                        <div className="w-100" style={{ position: "relative" }} >
                            <div className={"d-flex flex-row align-items-center"}>
                                <Form.Control type="text" id="" name="saleSearch" className="select-sale" placeholder={"search by sale title"} value={this.state.selectedSale?.title ? this.state.selectedSale?.title : this.state.saleSearch} required
                                    disabled={((this.props.location.state?.name && this.props.location.state.name == "Saledashboard") || this.state.isPaidCreate)}
                                    onChange={(event) => {
                                        this.onChangeInput(event)
                                    }}
                                />{(this.state.onsearchSale ?
                                    <Button variant="seller-search"  className="searchbutton">
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </Button> :
                                    <Button variant="seller-search" className="searchbutton" style={{backgroundColor: "#00263E"}} onClick={() => { this.getSaleList() }}><i style={{ fontSize: "18px" }} className="fa fa-search " ></i></Button>)}
                            </div>
                            {this.state.saleSearch && this.state.searchListView && this.state.salelist.length > 0 &&
                                <div className="search-sale-list">
                                    {this.state.salelist.map((item, index) => (
                                        <p style={{ borderWidth: index === 0 && 0 }} onClick={() => {
                                            this.onChangeInput({ target: { name: "sale", value: item } })
                                        }}>{item.title}</p>
                                    ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="splitter"></div>
                    {Object.keys(this.state.selectedSale).length > 0 && <div>
                        <div className="sale-container">
                            <div className="sale-title"></div>
                            <div class="form-check">
                                {invoiceType.map((item, key) => (<button disabled={this.state.isPaidCreate || this.state.itemLoading} onClick={() => this.getInvoiceTypeData(item)}>
                                    <div className="outer-circle">
                                        {this.state.invoiceType === item && <div className="inner-circle"></div>}
                                    </div>
                                    <div>Create {item}</div>
                                </button>))}
                            </div>
                        </div>
                        {this.state.invoiceType === invoiceType[1] && <div className="sale-container">
                            <div className="sale-title">Invoice Number</div>
                            <Form.Control as="select" id="" name="invoiceID" disabled={this.state.isPaidCreate}
                                className="select-sale select-invoice-number" required onChange={(event) => this.onChangeInput(event)}>
                                {this.state.isPaidCreate ?
                                    <option selected value={`${this.state.invoiceID}-${this.state.invoiceNumber}`}>{this.state.invoiceNumber}</option>
                                    : <option disabled={true} value={""} selected>Select Invoice Number</option>}
                                {
                                    this.state.invoiceList.filter(item => (item.type !== 'RA' && item.type !== 'DG')).map((item, index) => (
                                        <option value={`${item.id}-${item.invoicenumber}`}>{item.invoicenumber}</option>
                                    ))
                                }

                            </Form.Control>
                        </div>}
                    </div>}
                    <div className="splitter"></div>
                </div>}

                <div>

                </div>
                {this.state.itemLoading ?
                    <div className="item-loader-view">
                        <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                    </div>
                    : (this.state.itemError) ?
                        <div className="item-loader-view">
                            <div className="errormessage">{this.state.itemError}</div>
                        </div>
                        :
                        <div>
                            {((this.state.invoiceType === "EDIT" ||
                                (this.state.invoiceType === invoiceType[1] ? (Object.keys(this.state.selectedSale).length > 0 && this.state.invoiceNumber !== "")
                                    : Object.keys(this.state.selectedSale).length > 0))
                            ) && <div>
                                    <div className="contact-container">
                                        <div className="billing-view">
                                            <div className="billing-header" style={{ position: "relative" }}>
                                                <Form.Label className="text-secondary">Billing To</Form.Label>
                                                <div className="w-100" >
                                                    <div className="d-flex flex-row">
                                                        {!this.state.invoiceNumber && <DebounceInput type="text" className="debounce" name="billerSearchValue" placeholder={"search by buyer email"} debounceTimeout={1000}
                                                            value={this.state.billerSearchValue} onChange={(event) => this.searchBidder(event.target.value)} />}
                                                        {!this.state.invoiceNumber && (this.state.isinBillerSearch ?
                                                            <Button  variant="seller-search" className="searchbutton" style={{backgroundColor: "#00263E"}}>
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                            </Button> :
                                                            <Button variant="seller-search" className="searchbutton" style={{backgroundColor: "#00263E"}} onClick={() => this.onSearchBillers()}><i style={{ fontSize: "18px" }} className="fa fa-search " ></i></Button>)}

                                                    </div>
                                                    <div>

                                                        {this.state.billerSearchValue && this.state.isOnBillerSearch && this.state.billerList.length === 0 &&
                                                            <span className="biller-no-data">no bidder detail found</span>}

                                                        {(this.state.billerSearchValue && this.state.isOnBillerSearch) && this.state.billerList.length > 0 &&
                                                            <div className="biller-list-view">
                                                                {this.state.billerList.map((detail, index) => (
                                                                    <div onClick={() => {
                                                                        this.setState({
                                                                            istaxexempt: detail["istaxexempt"] ? true : false,
                                                                            billingAddr: detail,
                                                                            billerSearchValue: "",
                                                                            isOnBillerSearch: false,
                                                                        }, () => { this.oncalculateTaxException() })
                                                                    }} className="d-flex flex-row biller-item" style={{ borderWidth: index === 0 && 0 }} >
                                                                        <div>
                                                                            <p className="name">{`${detail["firstname"]}  ${detail["lastname"]}`}</p>
                                                                            <p className="address">{detail["address1"]}</p>
                                                                            <p className="city">{detail["city"]}</p>
                                                                            <p className="country">{`${detail["country"]} - ${detail["zipcode"]}`}</p>
                                                                            <p className="email">{detail["email"]}</p>
                                                                            <p className="mobile">{detail["mobile"]}</p>
                                                                        </div>
                                                                        <div>
                                                                            <div className="checkbox" onClick={()=>{this.getShippingBuyerAddress(detail["id"])}}>
                                                                                {this.state.billingAddr["email"] === detail["email"] && <i className="fa fa-check" ></i>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {this.state.billingAddr["firstname"] && this.state.billingAddr["lastname"] && <p className="name">{`${this.state.billingAddr["firstname"]}  ${this.state.billingAddr["lastname"]}`}</p>}
                                                {(this.state.billingAddr["address1"] || this.state.billingAddr['address2']) && <p className="address" style={{ width: '50%' }}>{this.state.billingAddr["address1"] ? `${this.state.billingAddr['address1']},` : ''} {this.state.billingAddr["address2"] ? this.state.billingAddr['address2'] : ''}</p>}
                                                {(this.state.billingAddr["city"] || this.state.billingAddr["state"]) && <p className="city">{this.state.billingAddr["city"] ? `${this.state.billingAddr["city"]}, ` : ''} {this.state.billingAddr["state"] ? this.state.billingAddr["state"]: ''}</p>}
                                                {this.state.billingAddr["country"] && this.state.billingAddr["zipcode"] && <p className="country">{`${this.state.billingAddr["country"]} - ${this.state.billingAddr["zipcode"]}`}</p>}
                                                {this.state.billingAddr["email"] && <p className="email">{this.state.billingAddr["email"]}</p>}
                                                {this.state.billingAddr["mobile"] && <p className="mobile">{this.state.billingAddr["mobile"]}</p>}
                                            </div>
                                        </div>
                                        <div className="pickup-view">
                                            <text className="text-secondary">Pickup Location</text>
                                            {this.state.pickup?.contactname && <p className="name">{this.state.pickup["contactname"]}</p>}
                                            {(this.state.pickup?.address1 || this.state.pickup?.address2) && <p className="address">{this.state.pickup["address1"] ? `${this.state.pickup["address1"]},` : ''} {this.state.pickup["address2"]  ? `${this.state.pickup["address2"]},` : ''} </p>}
                                            {(this.state.pickup?.city || this.state.pickup?.state ) && <p className="city">{this.state.pickup["city"] ? `${this.state.pickup["city"]},` : ''}  {this.state.pickup["state"] ? this.state.pickup["state"]: ''}</p>}
                                            {this.state.pickup?.country && this.state.pickup["zipcode"] && <p className="country">{`${this.state.pickup["country"]} - ${this.state.pickup["zipcode"]}`}</p>}
                                            {this.state.pickup?.contactemail && <p className="email">{this.state.pickup["contactemail"]}</p>}
                                            {this.state.pickup?.contactmobile && <p className="mobile">{this.state.pickup["contactmobile"]}</p>}
                                        </div>
                                        {(this.state.showShippingAddress && this.state.shippingAddr) ? <div className="shipping-view">
                                            <text className="text-secondary">Shipping To</text>
                                            {(this.state.shippingAddr?.address1 || this.state.shippingAddr?.address2) && <p style={{ width: '70%' }}  className="address">{this.state.shippingAddr["address1"] ? `${this.state.shippingAddr["address1"]},` : ''} {this.state.shippingAddr["address2"]  ? `${this.state.shippingAddr["address2"]},` : ''} </p>}
                                            {(this.state.shippingAddr?.city || this.state.shippingAddr?.state ) && <p className="city">{this.state.shippingAddr["city"] ? `${this.state.shippingAddr["city"]},` : ''}  {this.state.shippingAddr["state"] ? this.state.shippingAddr["state"]: ''}</p>}
                                            {this.state.shippingAddr?.country && this.state.shippingAddr["zipcode"] && <p className="country">{`${this.state.shippingAddr["country"]} - ${this.state.shippingAddr["zipcode"]}`}</p>}
                                        </div> : ''}
                                        <div></div>
                                    </div>
                                    <div className="splitter"></div>
                                    <div className="button-container">
                                        {((this.state.invoiceType === invoiceType[0] || this.state.invoiceType === "EDIT") && this.state.itemList.length > 0) && <Button
                                            disabled={this.state.itemList.filter(data => this.state.selectedItemList.findIndex(item => data.id === item.id) < 0).length == 0 || this.state.additemDisable || this.state.additemloading || !this.state.buyerid}
                                            className="save-button m-1" onClick={() => this.onItemAdd(true)}>Add Item {this.state.additemloading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : '' }</Button>}
                                        <Button
                                            disabled={this.state.selectedService.length === feeType.length}
                                            className="save-button m-1" onClick={() => this.onServiceAdd()}>Add Service</Button>
                                        <Button className="save-button m-1" onClick={() => this.onNotesAdd()}>{this.state.addNotesView ? "Remove Notes" : "Add Notes"}</Button>

                                    </div>
                                    {((this.state.invoiceType === invoiceType[0] || this.state.invoiceType === "EDIT") && this.state.selectedItemList.length > 0) && <div>
                                        <Table className="amountfields" responsive style={{ height: "100%" }}>
                                            <thead>
                                                <tr style={{ color: "#A50050", flexWrap: "nowrap" }}>
                                                    <td></td>
                                                    <td style={{ paddingLeft: "2.5rem" }}>Items</td>
                                                    <td>SKU</td>
                                                    {!this.state.invoiceview ? <td style={{width: '50px'}}>IRM</td> : null}
                                                    <td>Amount ($)</td>
                                                    <td className={this.state.istaxexempt && "istaxexempt"}>Tax (%)</td>
                                                    <td className={this.state.istaxexempt && "istaxexempt"}>Tax Amount</td>
                                                    <td>BP (%)</td>
                                                    <td>BP Amount</td>
                                                    <td className={this.state.istaxexempt && "istaxexempt"}>BP Tax Amount</td>
                                                    <td style={{ paddingRight: "2.5rem" }}>Gross Amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.selectedItemList.map((item, index) => (
                                                        <tr>
                                                            <td>
                                                                <div onClick={() => this.onRemoveItem("selectedItemList", item, index)}>
                                                                    <i className="fa fa-times remove" ></i>
                                                                </div>
                                                            </td>
                                                            <td style={{ paddingLeft: "2.5rem" }}>
                                                                <Form.Control as="select" id={index} name="title" className="select-item" disabled={item.hasOwnProperty("isAdd")}
                                                                    required onChange={(event) => this.onchangeItem(event, index, item.id)}>
                                                                    <option disabled={true} value={""} selected>Item Title</option>
                                                                    {this.state.itemList.findIndex(i => i.id == item.id) < 0 ?
                                                                        <option disabled={true} value={item.title} selected>{item.title}</option>
                                                                        :
                                                                        this.state.itemList.filter(i => ((i["invoicenumber"] === null) ||
                                                                            (item.id === i.id))).map((fullItem, key) => (
                                                                                <option selected={fullItem.id === item.id}
                                                                                    disabled={this.state.selectedItemList.map(d => { return (d.id) }).includes(fullItem.id)}
                                                                                    value={key}>{fullItem.title}</option>
                                                                            ))
                                                                    }

                                                                </Form.Control>
                                                            </td>
                                                            <td>{item.SKU}</td>

                                                            {!this.state.invoiceview ?<td>
                                                                {item.itemreceiptmethod ?
                                                                    <Form.Control as="select" name="bidderirm" style={{ width: '100px' }} onChange={(event) => this.onchangeItem(event, index, item.id)}>
                                                                        {
                                                                            item.itemreceiptmethod && typeof item.itemreceiptmethod === 'string' ?
                                                                                Object.keys(JSON.parse(item.itemreceiptmethod)).filter(key => JSON.parse(item.itemreceiptmethod)[key] === true).map((irmvalue, index) => {
                                                                                    return <option key={irmvalue} value={irmvalue ? irmvalue : ''} selected={item.bidderirm ? item.bidderirm === irmvalue : index == 0}> {irmvalue ? this.captializeLetter(irmvalue): '- - -'} </option>
                                                                                }) :
                                                                                null
                                                                        }
                                                                    </Form.Control> : '- - -'}
                                                            </td> : null}
                                                        
                                                            <td>
                                                                <div className="input-tag-container">
                                                                    <div>$ </div>
                                                                    <Form.Control type="text" name="amount" value={item["amount"] ? item["amount"] : ""} onChange={(event) => this.onchangeItem(event, index)} />
                                                                </div>
                                                            </td>
                                                            <td className={(this.state.istaxexempt && "istaxexempt")}>
                                                                <div className="input-tag-container" >
                                                                    <Form.Control disabled={this.state.istaxexempt || !item["istaxable"]} type="text" name="tax" value={item["tax"]} onChange={(event) => this.onchangeItem(event, index)} />
                                                                    <div>  % </div>
                                                                </div>
                                                            </td>
                                                            <td className={(this.state.istaxexempt || !item["istaxable"]) && "istaxexempt"}>{item["taxamount"] ? `$ ${parseFloat(item["taxamount"]).toFixed(2)}` : "- - -"}</td>
                                                            <td>
                                                                <div className="input-tag-container">
                                                                    <Form.Control type="text" name="buyerspremium" value={item["buyerspremium"] !== null ? item["buyerspremium"] : 0} onChange={(event) => this.onchangeItem(event, index)} />
                                                                    <div>  % </div>
                                                                </div>
                                                            </td>
                                                            <td>{item["buyerspremiumamount"] ? `$ ${parseFloat(item["buyerspremiumamount"]).toFixed(2)}` : "- - -"} </td>
                                                            <td className={`${this.state.istaxexempt && "istaxexempt"} ${this.state.applytaxonbp && "taxonBPamount"}`}>{item["taxonBPamount"] ? `$ ${parseFloat(item["taxonBPamount"]).toFixed(2)}` : "- - -"}</td>
                                                            <td style={{ paddingRight: "2.5rem" }}>{item["grossamount"] ? `$ ${parseFloat(item["grossamount"]).toFixed(2)}` : "- - -"}</td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td className="total-td"></td>
                                                    <td className="total-td" style={{ paddingLeft: "2.5rem" }}></td>
                                                    <td className="total-td"></td>
                                                    {!this.state.invoiceview ? <td className="total-td"></td> : null}
                                                    <td className="total-td">{this.state.totalAmount > 0 && `$ ${this.state.totalAmount}`}</td>
                                                    <td className={`${this.state.istaxexempt ? "istaxexempt" : ""} total-td`}></td>
                                                    <td className={`${this.state.istaxexempt ? "istaxexempt" : ""} total-td`}>{this.state.totalTax > 0 && `$ ${this.state.totalTax}`}</td>
                                                    <td className="total-td"></td>
                                                    <td className="total-td">{this.state.totalBP > 0 && `$ ${this.state.totalBP}`}</td>
                                                    <td className={`${this.state.istaxexempt ? "istaxexempt" : ""} total-td`}></td>
                                                    {/* <td className={"total-td"}>$ {this.state.totalAmount}</td> */}
                                                    <td className="total-td" style={{ paddingRight: "2.5rem" }}>{this.state.totalGross > 0 && `$ ${this.state.totalGross}`}</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                    }
                                    <div>
                                        <div className="footer-container">
                                            <div className="fee-container">
                                                {this.state.selectedService.map((item, index) => (
                                                    <div className="fee-content">
                                                        <button onClick={() => this.onRemoveItem("selectedService", item, index)}>
                                                            <i className="fa fa-times remove" ></i>
                                                        </button>
                                                        <Form.Control as="select" id="" name="service" className="select-service" required onChange={(event) => this.onchangeService(event, index)}>
                                                            {/* <option disabled={true} value={""} selected>select </option> */}
                                                            {
                                                                feeType.map((fullItem, key) => (
                                                                    <option selected={fullItem.name === item.name} disabled={this.state.selectedService.includes(fullItem)} value={key}>{fullItem.name}</option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        <Form.Control type="text" name={"remark"} value={item["remark"] ? item["remark"] : ""} onChange={(event) => this.onchangeService(event, index)} placeholder={item.name.includes("Additional") ? 'Additional Details' : item.name + ' Details'} />
                                                        <text>:</text>
                                                        <div className="input-tag-container">
                                                            <div>$</div>
                                                            <Form.Control type="text" name={"amount"} value={item["amount"] ? item["amount"] : ""} onChange={(event) => this.onchangeService(event, index)} /></div>
                                                    </div>
                                                ))}
                                                {(this.state.selectedItemList.length > 0 ||
                                                    this.state.selectedService.length > 0) &&
                                                    <div className="fee-content">
                                                        <div className="total-invoice total-amount-text" style={{ width: "80%", display: "flex", placeContent: "flex-end" }}>
                                                            Total Amount :
                                                        </div>
                                                        <div className="total-invoice total-amount-text" style={{ width: "20%" }}>
                                                            $&nbsp;{this.state.totalInvoiceAmount}
                                                        </div>
                                                        {/* <button></button>
                                                        <div style={{ padding: "0% 0.5%" }} className="total-invoice"></div>
                                                        <div className="total-invoice  total-amount">Total Amount </div>
                                                        <text>:</text>
                                                        <div className="total-invoice total-amount-text text-right">
                                                            $&nbsp;&nbsp;{this.state.totalInvoiceAmount}
                                                        </div> */}
                                                    </div>}
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        {this.state.addNotesView && <div className="add-notes-container">
                                            <div>
                                                <text className="add-notes-title">Additional Notes</text>
                                                <text>&nbsp;{`${this.state.additionalNotes.length}/500`}</text>
                                            </div>
                                            <textarea maxLength='500'
                                                className="input-add-notes" type="textarea" name="additionalNotes" value={this.state.additionalNotes} onChange={(event) => this.onChangeInput(event)} />
                                        </div>
                                        }
                                    </div>

                                </div>
                            }</div>
                }
                <Modal size="xl" centered show={this.state.previewModal} id="previewmodalview" style={{ maxHeight: "90%" }}>
                    <Modal.Body>
                        <div className="invoice-create-container">
                            <div className="header">
                                <img src={`${process.env.PUBLIC_URL}/static/logo/CTBids_logo.png`} alt="logo" height="60px" width="170px" />
                                <div className="header-button">
                                    <button onClick={() => this.setState({ previewModal: false })}>
                                        <i className="fa fa-times remove" ></i>
                                    </button>
                                </div>
                            </div>
                            {(this.state.invoiceType === "EDIT") &&
                                <div>
                                    <div className="invoice-number-container">
                                        <div></div>
                                        <div className="invoice-number-content">
                                            <p className="invoice-number-left">Invoice Number : </p>
                                            <p className="invoice-number-right">{this.state.invoiceNumber}</p>
                                        </div>
                                    </div>
                                    <div className="splitter"></div>
                                </div>
                            }
                            <div className="splitter"></div>
                            <div className="contact-container">
                                <div className="preview-billing-view">
                                    <text className="text-secondary">Billing To</text>
                                    {this.state.billingAddr["firstname"] && this.state.billingAddr["lastname"] && <p className="name">{`${this.state.billingAddr["firstname"]}  ${this.state.billingAddr["lastname"]}`}</p>}
                                    {this.state.billingAddr["address1"] && <p style={{ width: '50%' }} className="address">{this.state.billingAddr["address1"]} {this.state.billingAddr["address2"] ? this.state.billingAddr['address2'] : ''} </p>}
                                    {(this.state.billingAddr["city"] || this.state.billingAddr["state"] ) && <p className="city">{this.state.billingAddr["city"] ?  `${this.state.billingAddr["city"]},`  : ''} {this.state.billingAddr["state"] ? this.state.billingAddr["state"]: ''}</p>}
                                    {this.state.billingAddr["country"] && this.state.billingAddr["zipcode"] && <p className="country">{`${this.state.billingAddr["country"]} - ${this.state.billingAddr["zipcode"]}`}</p>}
                                    {this.state.billingAddr["email"] && <p className="email">{this.state.billingAddr["email"]}</p>}
                                    {this.state.billingAddr["mobile"] && <p className="mobile">{this.state.billingAddr["mobile"]}</p>}
                                </div>
                                <div className="preview-pickup-view">
                                    <text className="text-secondary">Pickup Location</text>
                                    {this.state.pickup?.contactname && <p className="name">{this.state.pickup["contactname"]}</p>}
                                    {this.state.pickup?.address1 && <p className="address">{this.state.pickup["address1"]}</p>}
                                    {(this.state.pickup?.city || this.state.pickup?.state )&& <p className="city"> {this.state.pickup["city"] ? `${this.state.pickup["city"]},` : '' } {this.state.pickup["state"] ? this.state.pickup["state"]: ''}</p>}
                                    {this.state.pickup?.country && this.state.pickup["zipcode"] && <p className="country">{`${this.state.pickup["country"]} - ${this.state.pickup["zipcode"]}`}</p>}
                                    {this.state.pickup?.contactemail && <p className="email">{this.state.pickup["contactemail"]}</p>}
                                    {this.state.pickup?.contactmobile && <p className="mobile">{this.state.pickup["contactmobile"]}</p>}
                                </div>
                                {(this.state.showShippingAddress && this.state.shippingAddr) ? <div className="preview-pickup-view">
                                    <text className="text-secondary">Shipping To</text>
                                    {(this.state.shippingAddr?.address1 || this.state.shippingAddr?.address2) && <p className="address">{this.state.shippingAddr["address1"] ? `${this.state.shippingAddr["address1"]},` : ''} {this.state.shippingAddr["address2"] ? `${this.state.shippingAddr["address2"]},` : ''} </p>}
                                    {(this.state.shippingAddr?.city || this.state.shippingAddr?.state) && <p className="city">{this.state.shippingAddr["city"] ? `${this.state.shippingAddr["city"]},` : ''}  {this.state.shippingAddr["state"] ? this.state.shippingAddr["state"] : ''}</p>}
                                    {this.state.shippingAddr?.country && this.state.shippingAddr["zipcode"] && <p className="country">{`${this.state.shippingAddr["country"]} - ${this.state.shippingAddr["zipcode"]}`}</p>}
                                </div> : '' }
                            </div>
                            {this.state.selectedItemList.length > 0 && <div>
                                <Table className="amountfields" responsive style={{ height: "100%" }}>
                                    <thead>
                                        <tr style={{ color: "#A50050", flexWrap: "nowrap" }}>
                                            <td style={{ paddingLeft: "2.5rem" }}>Items</td>
                                            <td>SKU</td>
                                            <td>IRM</td>
                                            <td>Amount ($)</td>
                                            <td className={this.state.istaxexempt && "istaxexempt"}>Tax (%)</td>
                                            <td className={this.state.istaxexempt && "istaxexempt"}>Tax Amount</td>
                                            <td>BP (%)</td>
                                            <td>BP Amount</td>
                                            <td className={(this.state.applytaxonbp && "taxonBPamount")}>BP Tax Amount</td>
                                            <td style={{ paddingRight: "2.5rem" }}>Gross Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.selectedItemList.map((item, index) => (
                                                <tr>
                                                    <td style={{ paddingLeft: "2.5rem" }}>
                                                        <div className="select-item" >{item.title}</div>
                                                    </td>
                                                    <td>{item.SKU}</td>
                                                    <td>{item.bidderirm ? item.bidderirm.replace('pickup', 'P').replace('shipping', 'S').replace('delivery', 'D') : '- - -' }</td>
                                                    <td>$ {item["amount"]}</td>
                                                    <td className={this.state.istaxexempt && "istaxexempt"}>{item["tax"]} %</td>
                                                    <td className={this.state.istaxexempt && "istaxexempt"}>{item["taxamount"] ? `$ ${parseFloat(item["taxamount"]).toFixed(2)}` : "- - -"}</td>
                                                    <td>{item["buyerspremium"] !== null ? item["buyerspremium"] : 0}%</td>
                                                    <td>{item["buyerspremiumamount"] ? `$ ${parseFloat(item["buyerspremiumamount"]).toFixed(2)}` : "- - -"}</td>
                                                    <td className={this.state.applytaxonbp}>{item['taxonBPamount'] ? `$ ${parseFloat(item["taxonBPamount"]).toFixed(2)}` : "- - -"}</td>
                                                    <td style={{ paddingRight: "2.5rem" }}>${item["grossamount"] ? item["grossamount"] : ""}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td style={{ paddingLeft: "2.5rem" }}></td>
                                            <td></td>
                                            <td></td>
                                            <td>{this.state.totalAmount > 0 && `$ ${this.state.totalAmount}`}</td>
                                            <td className={this.state.istaxexempt && "istaxexempt"}></td>
                                            <td className={this.state.istaxexempt && "istaxexempt"}>{this.state.totalTax > 0 && `$ ${this.state.totalTax}`}</td>
                                            <td></td>
                                            <td>{this.state.totalBP > 0 && `$ ${this.state.totalBP}`}</td>
                                            <td className={this.state.istaxexempt && "istaxexempt"}></td>
                                            {/* <td>$ {this.state.totalAmount}</td> */}
                                            <td style={{ paddingRight: "2.5rem" }}>{this.state.totalGross > 0 && `$ ${this.state.totalGross}`}</td>
                                        </tr>

                                    </tbody>
                                </Table>

                                <div className="splitter"></div>
                            </div>
                            }
                        </div>
                        <div className="footer-container">
                            <div className="fee-container">
                                {this.state.selectedService.map((item, index) => (
                                    <div className="fee-content">
                                        <div className="item-left">{item.name}</div>
                                        <div className="fee-remark">{item["remark"]}</div>
                                        <div className="amount-right">
                                            <div> : </div>
                                            <div className="service-amount">{((item["amount"]) || item["amount"]) ? `$ ${parseFloat(item["amount"]).toFixed(2)}` : "- - -"}</div>
                                        </div>
                                    </div>
                                ))}
                                <div className="fee-content">
                                    <div className="fee-remark"></div>
                                    <div className="item-left text-right" >Total Amount </div>
                                    <div className="amount-right">
                                        <div> : </div>
                                        <div className="service-amount total-amount-text">$ {parseFloat(this.state.totalInvoiceAmount).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                            {this.state.additionalNotes !== "" && <div className="add-notes-container">
                                <div className="add-notes-title">Additional Notes</div>
                                <div className="add-notes-content">{this.state.additionalNotes}</div>
                                {/* <textarea className="input-add-notes" type="textarea" name="additionalNotes" value={this.state.additionalNotes} onChange={(event) => this.onChangeInput(event)} /> */}
                            </div>}
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}
export default InvoiceCreate;